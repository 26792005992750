// Styles for the _welcome.slim template
// Include these styles AFTER the shared template styles and BEFORE any
// project-specific overwrites

.lead-constructor__panel--welcome,
.lead-constructor__panel--welcome-alternate {

  // overwrite shared styles to keep panel always centered
  &.lead-constructor__panel {
    @include media($width-l) {
      text-align: center; } }

  .lead-constructor__question-block {
    margin-bottom: 20px; }

  .lead-constructor__heading {
    @include type(28, 34);
    text-align: center;
    margin: 0 0 $grid-gutter;

    @include media($width-l) {
      @include type(36, 42); } }

  .lead-constructor__subheading {
    font-weight: $font-primary-weight-regular;
    font-family: $font-primary;
    @include type(16, 24);
    margin: 0 0 $grid-gutter;

    @include media($width-l) {
      @include type(18, 24); } }

  // overwrite shared styles
  .lead-constructor__content {
    text-align: center;
    top: 50px;
    transform: translateY(0); }

  p.guarantee {
    @include type(14, 21);
    font-style: italic;
    margin: $grid-gutter auto; }

  .lead-constructor__question-block > {
    .lead-constructor__eyebrow--split-text {
      display: none;
      @include media($width-l) {
        display: block; } } } }
