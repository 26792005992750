// Styles for the _form-fields.slim template
// Include these styles AFTER the shared template styles and BEFORE any
// project-specific overwrites

.lead-constructor__form-fields {

  .lead-constructor__answer-block {
    margin: 0 auto;
    max-width: $max-button-width;
    text-align: left;

    @include media($width-l) {
      margin: 0; }

    .form__label-text {
      @include type(16, 22);
      // overwrites absolute position which makes it disappear on large screens
      position: relative; }

    .form__label-lock_icon {
      display: inline-block;
      height: 24px;
      width: 24px;
      float: right;

      > svg {
        cursor: default;
        max-height: 100%; } }

    input,
    textarea {
      @include type(18, 18);
      border: none;
      border-radius: 0;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, .5);
      height: 50px; }

    textarea {
      height: 75px;
      max-width: 260px; }

    .form__error {
      // centers form error in input field
      bottom: 8px; }

    .form__field--checkbox {
      position: relative;
      margin: 0 0 2em;

      // override error styles for checkboxes
      .form__error {
        background-color: transparent;
        color: color(mandy);
        bottom: -25px;
        left: 10px;

        &::before {
          content: none; } } }

    input[type="checkbox"] {
      height: 14px; } }

  .lead-constructor__question.edge-heading.slide-subheading {
    font-size: 20px; } }
