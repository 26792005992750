.edge-button--primary {
  min-width: 200px;
  max-width: 210px;
  background-color: $vt2-blue;
  border-radius: 22px;
  border: 1px solid $vt2-blue;
  color: color(white);
  text-transform: none;
  font-family: $font-vt2;
  font-weight: 700;
  svg {
    display: none; }

  &:hover,
  &:active {
    background-color: $vt2-blue;
    border: 1px solid $vt2-blue; } }
