// Styles for the _progress-bar.slim partial
// Include these styles AFTER the shared template styles and BEFORE any
// project-specific overwrites

// override by defining new constant value in sass file and importing this file beneath it
$progress-bar-display-start: none !default;
$progress-bar-display-width-l: none !default;
$progress-bar-height: 10px !default;
$progress-bar-total-border-color: brand(dark-gray) !default;
$progress-bar-total-color: color(white) !default;
$progress-bar-total-height: 10px !default;
$progress-bar-indicator-color: edge-color(star-yellow) !default;

.progress-wrapper {
  // optionally hide progress bar on welcome panel
  display: $progress-bar-display-start;
  max-width: 300px;
  margin: 0 auto; }

// once welcome panel is complete, show progress bar
// ('interacted' class added only if welcome panel is complete)
.leadform.interacted .progress-wrapper {
  display: block;

  @include media($width-l) {
    // optionally hide progress bar at width-l
    display: $progress-bar-display-width-l; } }

.progress-bar {
  height: $progress-bar-height;
  margin: $grid-gutter / 2 0;
  position: relative; }

.progress-bar__total {
  background-color: $progress-bar-total-color;
  border: 1px solid $progress-bar-total-border-color;
  box-sizing: border-box;
  height: $progress-bar-total-height;
  left: 0;
  position: absolute;
  top: calc(50% - #{$progress-bar-total-height} / 2);
  width: 100%; }

.progress-bar__indicator {
  background-color: $progress-bar-indicator-color;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width .25s ease-in-out;
  width: 0; }
