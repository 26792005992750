// Styles for the _radio-buttons.slim template
// Include these styles AFTER the shared template styles and BEFORE any
// project-specific overwrites

.lead-constructor__panel--radio-buttons {

  .lead-constructor__answer-block {
    &.two-column {
      @include media($width-m) {
        break-inside: avoid;
        column-count: 2;
        max-width: 500px;
        margin: auto; }

      @include media($width-l) {
        max-width: none; }

      .lead-constructor__button {
        @include media($width-m) {
          display: inline-block; }

        &.lead-constructor__button--hidden {
          @include media($width-m) {
            display: none; } } } } }

  .lead-constructor__radio {
    display: none; }

  .lead-constructor__button {
    display: block;
    text-transform: none;
    margin: 0 auto $grid-gutter/2;
    max-width: $max-button-width;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0; }

    // make sure link and labels still have space between them
    &.lead-constructor__button--link {
      margin-bottom: $grid-gutter/2; }

    &:active {
      background-color: edge-color(accessible-blue);
      color: color(white); }

    &.lead-constructor__button--hidden {
      display: none; }

    // change buttons to plain text
    @include media($width-l) {
      background: transparent;
      border: none;
      cursor: pointer;
      font-family: $font-primary;
      letter-spacing: normal;
      margin: $grid-gutter/4 0;
      max-width: unset;
      padding: $grid-gutter/4 0;
      text-align: left;
      @include type(24, 36);

      &:hover,
      &:active {
        background: transparent;
        box-shadow: none;
        color: edge-color(hot-pink); } } } }
