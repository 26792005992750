@import '~vt-frontend-engine/app/styles/principles/variables/all-edge';
@import '~vt-frontend-engine/app/styles/principles/mixins/all-edge';

.vt-leadform-wizard--vt_learning_pods_hagrid {

  // Variables
  @import '../../../variables/buttons';
  @import '../../../variables/vt2-colors';
  @import '../../../variables/vt2-font-stack';
  @import '../../../variables/vt2-button';

  // Components
  @import '../../../components/progress_indicators/progress-bar';
  @import '../../../components/templates/welcome';
  @import '../../../components/templates/radio-buttons';
  @import '../../../components/templates/form-fields';
  @import '../../../overwrites/wizard/basics/vt2_theme';

  .lead-constructor__content {
    padding-top: 30px;

    @include media($width-l) {
      padding-top: 0; } }

  .vt-leadform-wizard {
    font-family: $font-primary; }

  .leadform--wizard {
    height: 100%; }

  // hide back button on Name panel
  .lead-constructor__panel:first-of-type {
    .js-lead-constructor__back {
      display: none; } }

  .progress-wrapper {
    display: block;
    max-width: 250;
    margin: 18px auto;

    @include media($width-l) {
      display: none; } } }
