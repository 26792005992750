// override font family to match vt2
.edge-heading,
.edge-button,
.guarantee,
.slide-question,
.lead-constructor__back,
.lead-constructor__button,
.form__field,
.form__error {
  font-family: $font-primary; }

// change form error colour
.form__error {
  background-color: $vt2-blue; }

.form__error:before {
  border-right: 1.1em solid $vt2-blue; }

.lead-constructor__button.edge-button.edge-button--secondary {
  &:hover {
    color: $vt2-blue; } }
